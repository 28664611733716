<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content 店铺详情
-->
<template>
  <comprehensive>
    <div v-if="loading" class="shopdetail-body">
      <div class="shops_infoBag">
        <div class="top_left">
          <div class="shops_img">
            <div
              class="defaultImg"
              style="
                background: url(https://ddmallimg.ddmg.com/public/assets/img/index/shops/shopsImg.png)
                  no-repeat center;
                background-size: 100% 100%;
              "
            ></div>
          </div>
        </div>
        <div class="top_right">
          <h3
            style="
              color: #444;
              font-size: 22px;
              font-weight: bold;
              display: flex;
              align-items: center;
              margin-bottom: 20px;
            "
          >
            {{ companyDeatil.companyName}}
            <div
              class="im"
              style="cursor: pointer; display: flex;  color: #444;"
              data-id="1606"
              data-product_image=""
              :data-product_title="companyDeatil.companyName"
              :data-main="companyDeatil.main_products"
              :data-dec="'近三月成交' + companyDeatil.lastThreeMonthTrading + '万吨'"
              @click="clickCustomer()"
            >
              <img
                style="width: 17px; vertical-align: middle; margin-left: 4px"
                src="https://ddmallimg.ddmg.com/public/assets/img/index/index/ic_call3.png"
              />
              <span
                style="font-size: 14px; line-height: 17px; padding-left: 4px"
              >
                联系卖家
              </span>
            </div>
            <div class="title_label">
              近三月成交{{ companyDeatil.lastThreeMonthTrading }}万吨
            </div>
          </h3>
          <div class="top_right_text liangHang ">
            <div class="text_left">店铺介绍：</div>
            <div class="text_right content">
              <span v-if="companyDeatil.shopIntroduce">{{companyDeatil.shopIntroduce}}</span>
              <span v-else class="no_content" style="opacity: 0.65">暂无</span>
            </div>
          </div>
          <div class="top_right_text ">
            <div class="text_left">主营产品：</div>
            <div class="text_right content">
              <span v-if="companyDeatil.mainProducts">{{companyDeatil.mainProducts}}</span>
              <span v-else class="no_content" style="opacity: 0.65">暂无</span>
            </div>
          </div>
          <div class="top_right_text">
            <div class="text_left">联系方式：</div>
            <div class="text_right">{{ companyDeatil.phone}}</div>
          </div>
          <div class="top_right_text">
            <div class="text_left">联系地址：</div>
            <div class="text_right">
              {{ companyDeatil.address}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <stock />
  </comprehensive>
</template>

<script>
import comprehensive from "../../components/comprehensive.vue";
import stock from "./components/stock.vue";
import { mapState, mapActions } from "vuex";
export default {
  name:'shopdetail',
  components: {
    comprehensive,
    stock,
  },
  data() {
    return {
      shopList: {},
      loading:false,
    };
  },
  methods: {
    ...mapActions("ddmg/shop", ["getCompanyDeatil"]),
    clickCustomer(){
      this.toCustomer()
    },
  },
  computed: {
    ...mapState("ddmg/shop", {
      companyDeatil: (state) => state.companyDeatil,
    }),
  },
  mounted() {
    this.getCompanyDeatil(this.$route.query.id).then(res=>{
        this.loading = true
    });
  },
};
</script>

<style lang="scss" scoped>
.shopdetail-body {
  padding: 20px 0px;

}
.shops_infoBag {
  width: 1200px;
  height: 226px;
  display: flex;
  margin: 0 auto;
  background: url('https://ddmallimg.ddmg.com/public/assets/img/index/shops/topBag.jpg') no-repeat center
}

.shops_infoBag .top_left {
  width: 212px;

  margin: 20px;
  text-align: center;
  position: relative;
  background: url("https://ddmallimg.ddmg.com/public/assets/img/index/shops/topImg.png")
    no-repeat center;

}

.shops_infoBag .top_left .shops_img {
  width: 100%;
  height: 113px;
  position: absolute;
  bottom: 15px;
}

.shops_infoBag .top_left .shops_img .defaultImg {
  width: 150px;
  height: 113px;
  margin: 0 auto;
  background: url("https://ddmallimg.ddmg.com/public/assets/img/index/shops/shopsImg.png")
    no-repeat center;
  background-size: 100% 100%;
}

.shops_infoBag .top_right {
  width: 888px;

  margin: 20px 0 auto;

}

.shops_infoBag .top_right .title_label {
  padding: 8px 13px;
  font-size: 14px;
  display: inline-block;
  margin: 0px 15px;
  color: #ef2147;
  background-color: #ffe0e4;
  font-size: 14px;
  font-weight: 600;
}

.top_right .top_right_text {
  margin: 15px auto;
  display: flex;
}

.top_right .top_right_text .text_left {
  min-width: 75px;
  font-size: 14px;

  color: #444;
}

.top_right .top_right_text .text_right {
  font-size: 14px;
  color: #444;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.top_right .top_right_text .liangHang {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
</style>
