<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content 列表
-->
<template>
  <section
    class="stock-content"
    v-loading="loading"
  >
    <!--全部结果-->
    <div
      class="stock-condition"
      v-if="searchConditionsList.length > 0"
    >
      <div class="stock-condition-title">
        <p>当前搜索</p>
      </div>
      <div class="stock-condition-list">
        <div
          class="stock-condition-item"
          v-for="(items, index) in searchConditionsList"
          :key="index"
        >
          <p>{{ searchListHeadTitle[items.name] }}:{{ items.item }}</p>
          <img
            src="../../../assets/img/4@3x.png"
            @click="onDeleteCondtion(index,items)"
            alt="大大买钢"
          />
        </div>
        <div
          class="stock-condition-clear"
          @click="onDeleteCondtionAll"
        >
          清除全部
        </div>
      </div>
    </div>
    <div
      v-if="searchResultData.length == 0"
      class="empty-tips"
      style="width: 1200px;height: 35px;background-color:#fff7f2;margin: 9px auto;font-size: 14px;color: #333333;line-height: 35px;float: left;margin-top:0px"
    >
      <img
        style="margin-left: 20px;margin-top:-4px;width: 15px;height: 15px;margin-right: 10px;"
        src="https://ddmallimg.ddmg.com/public/assets/img/index/mall/gth.png"
        alt="大大买钢"
      >
      未找到符合条件的相关商品，重新<span style="color: #FF6200;">换个条件</span>试试
    </div>
    <!--数据明细-->
    <div
      :class="{
        'stock-detailed': true,
        'stock-detailed-up': isOpenDown == true,
      }"
      v-if="searchList.length > 0"
      :style="{ height: allSearchListHeight + 'px' }"
    >
      <div
        class="stock-detailed-list"
        v-for="(item, name, index) in searchList"
        :key="index"
      >
        <div class="detailed-list-title">{{ searchListHeadTitle[name] }}</div>
        <div
          class="detailed-list-Shell"
          :ref="name"
          :style="{ height: (clickHeight[index].childHeight + 1) * 46 + 'px' }"
        >
          <div
            v-for="(listItem, num) in item"
            :class="{
              'detailed-list-item': true,
              active: listItem.isActive == true,
            }"
            :key="num"
            @click="onConditions(listItem, name, num)"
          >
            <p v-if="
                name == 'productList' ||
                name == 'materialList' ||
                name == 'specificationList'
              ">
              {{ listItem.name }}
            </p>
          </div>
        </div>
        <!--请确认规格确定格子，放到class="detailed-list-mored"之中-->
        <div class="detailed-list-mored">
          <div
            class="detailed-list-specs"
            v-if="name == 'specificationList'"
          >
            <el-input
              v-model="searchInput"
              placeholder="请确认规格"
              size="mini"
            ></el-input>
          </div>
          <div
            class="detailed-list-sure"
            v-if="name == 'specificationList'"
          >
            <button
              class="specifications_input_search_confirm"
              @click="onMultipleChoice"
              style="border: 1px solid #DDDDDD;width: 50px;text-align: center;margin: 0; background-color: #ffffff;margin-left:10px;height:28px"
            >
              确认
            </button>
          </div>
        </div>
        <div
          class="detailed-list-img dis-none"
          @click="updowns(name, index)"
        >
          <div :class="{
              'list-img-mored': true,
              'list-img-mored-up': clickHeight[index].childHeight != 0,
            }"></div>
        </div>
      </div>
    </div>
    <div
      class="stock-product-box"
      v-if="searchResultData.length > 0"
    >
      <!--产品列表-->
      <div class="stock-product">
        <div class="stock-product-header">
          <div class="product-header-price">
            <p>价格</p>
            <img
              style="cursor: pointer;"
              src="../../../assets/img/2688.png"
              alt="大大买钢"
              @click="descOrasc('price')"
              :class="{ priceSort: priceSort == true }"
            />
          </div>
          <div class="product-header-time">
            <p>发布时间</p>
            <img
              style="cursor: pointer;"
              src="../../../assets/img/2688.png"
              alt="大大买钢"
              @click="descOrasc('create_time')"
              :class="{ timeSort: timeSort == true }"
            />
          </div>

          <!--          <div class="product-header-total">-->
          <!--            <p>-->
          <!--              共<span>{{ paginationTotal }}件</span>商品-->
          <!--            </p>-->
          <!--          </div>-->
          <!--          <div class="product-header-num">-->
          <!--            <img style="cursor: pointer;"-->
          <!--              src="../../../assets/img/10@3xl.png"-->
          <!--              alt="大大买钢"-->
          <!--              @click="gotoNext(nowpagination - 1)"-->
          <!--            />-->
          <!--            <p>-->
          <!--              <span>{{ nowpagination }}</span-->
          <!--              ><span>/</span><span>{{ Math.ceil(paginationTotal / 10) }}</span>-->
          <!--            </p>-->
          <!--            <img style="cursor: pointer;"-->
          <!--              src="../../../assets/img/10@3x.png"-->
          <!--              alt="大大买钢"-->
          <!--              @click="gotoNext(nowpagination + 1)"-->
          <!--            />-->
          <!--          </div>-->
        </div>
        <!--一条产品信息-->
        <div
          class="sock-product-item"
          v-for="(item, index) in searchResultData"
          :key="index"
        >
          <div class="content_div_content">
            <span @click="goAddOrder(item)">
              <div
                class="div_content"
                style="height: 18px;font-size: 18px;color: #333333;padding-left:16px"
              >

                {{ item.productName }} {{ item.material }} {{ item.specifications }} {{ item.originPlace }}
              </div>
              <div
                class="div_content"
                style="width:248px;"
              >
                <div style="color: #333333;padding-left: 30px">
                  <div>{{ item.city }}/{{ item.warehouseName }}</div>
                  <div style="color:#999999;margin-top:10px">
                    <span v-if=" item.weight > 0 || item.listingSource == 2">库存充足</span>
                    <span v-else>库存不足</span>
                  </div>
                </div>
              </div>
              <div
                class="div_content"
                style="color: #FF4747;width: 180px;padding-left:80px"
              >
                <span v-if="item.price >0"> ¥ <span style="font-size:24px;">{{ item.price }}</span></span>
                <span v-else> ¥ <span style="font-size:24px;">电议</span></span>
              </div>
            </span>
            <div
              class="div_content"
              style="width: 150px;"
            >
              <div
                class="div_content_bottom shop-cart"
                @click="addGWC(item)"
              >
                <div class="img_car"></div>
                加购物车
              </div>
            </div>
          </div>
          <!--          <div class="product-name">-->
          <!--            <p>-->
          <!--              {{ item.product_name }} {{ item.material }}-->
          <!--              {{ item.specifications }} {{ item.origin }}-->
          <!--            </p>-->
          <!--            <p style="font-size: 18px; color: #ef2147; font-weight: 900">-->
          <!--              ￥{{ item.price }}-->
          <!--            </p>-->
          <!--          </div>-->

          <!--          <div class="product-price">-->
          <!--            <p>{{ item.city_name }}/{{ item.warehouse_name }}</p>-->
          <!--            <p>-->
          <!--              {{ item.quantity }}件 / {{ item.weight-->
          <!--              }}{{ item.measurement_unit }}-->
          <!--            </p>-->
          <!--          </div>-->
          <!--          <div class="product-order">-->
          <!--            <div>-->
          <!--              <p><img src="../../../assets/img/gwch.svg" alt="大大买钢" />加入购物车</p>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <!--一条产品信息结束-->
      </div>
      <!--分页-->
      <div class="stock-paging">
        <el-pagination
          background
          layout="total,prev, pager, next,jumper"
          :total="paginationTotal"
          :page-size="pagenum"
          @current-change="gotoNext"
        >
        </el-pagination>
      </div>
    </div>

    <div
      class="div_404 empty-tips goods-empty-tips"
      v-else
    >
      <div class="div_404_left"></div>
      <div class="div_404_right">
        <h3 class="empty-tips-message">抱歉~该商铺暂未发布资源</h3>
        <h4>
          可以点击<span style="color: #666666">“我要求购”</span>去发布求购信息，客服会尽快联系您。
        </h4>
        <button
          class="btnqiug"
          style="cursor: pointer"
          @click="$router.push({ name: 'toBuy' })"
        >
          我要求购
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {},
  data () {
    return {
      loading: true,
      pagenum: 10,
      paginationTotal: 0, //总条数
      nowpagination: 1, //当前页码
      priceSort: false, //fakse降序，true升序
      timeSort: false, //fakse降序，true升序
      orderBy: [
        //类型：Array  必有字段  备注：排序字段
        {
          name: "price",
          order: "DESC", //默认降序
        },
        {
          name: "createTime",
          order: "DESC", //默认降序
        },
      ],
      isOpenDown: false,
      allSearchListHeight: 0,
      checkoriginPlaceList: [],
      checkCityList: [],
      isoriginPlaceListSingleChoice: true,
      iscityListSingleChoice: true,
      searchInput: "", //规格确定框的内容
      rules: {
        searchInput: [{ required: false, trigger: "blur" }],
      },
      clickHeight: [{ childHeight: 0 }, { childHeight: 0 }, { childHeight: 0 }],
      clickHeightNum: 0,
      isOriginPlaceList: false,
      isCityList: false,
      searchList: {
        productList: [],
        materialList: [],
        specificationList: [],
      },
      searchListHeadTitle: {
        productList: "品名",
        materialList: "材质",
        specificationList: "规格",
      },
      searchConditionsList: [], //选择地条件
      isSingleChoice: true, //是否是单选
      searchResultData: [], //根据搜索得到的结果
    };
  },
  methods: {
    ...mapActions("ddmg/shop", [
      "getCompanyDeatil",
      "shopDeatilData",
      "shopDeatilListData",
    ]),
    ...mapActions("ddmg/gwc", ["getAddGWC"]),
    addGWC (item) {
      let token = this.isToken();
      if (token) {
        this.getAddGWC(item).then(res => {
          if (res.msgcode === 1) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        });
      }
    },
    //点击商品跳转下单
    goAddOrder (item) {
      this.$router.push({ name: "product", query: { id: item.id } });
    },
    gotoScreen () {
      //点击检索条件
      let body = {
        sortables: this.orderBy,
        pages: 1,
        rows: 10,
        seller_company_name: this.$route.query.name,
        companyId: this.$route.query.id,
      };
      for (let i of this.searchConditionsList) {
        if (i.name == "specificationList") {
          body.specifications = i.item;
        } else if (i.name == "productList") {
          body.productName = i.item;
        } else if (i.name == "materialList") {
          body.material = i.item;
        }
      }
      this.shopDeatilListData(body).then((res) => {
        if (res.msgcode == 1) {
          this.searchResultData = res.resultdata.data;
          this.paginationTotal = res.resultdata.total;
          this.loading = false;
        }
      });
    },
    descOrasc (types) {
      if (types == "price") {
        this.priceSort = !this.priceSort;
        this.orderBy[0].order =
          this.orderBy[0].order == "DESC" ? "ASC" : "DESC";
      } else {
        this.timeSort = !this.timeSort;
        this.orderBy[1].order =
          this.orderBy[1].order == "DESC" ? "ASC" : "DESC";
      }
      this.gotoScreen();
    },
    gotoNext (val) {
      //分页
      if (val < 1 || val > Math.ceil(this.paginationTotal / 10)) {
        this.$message.error("当前没有更多页面了");
        return;
      }
      let body = {
        pages: val,
        rows: 10,
        sortables: this.orderBy,
        seller_company_name: this.$route.query.name,
        companyId: this.$route.query.id,
      };
      for (let i of this.searchConditionsList) {
        if (i.name == "specificationList") {
          body.specifications = i.item;
        } else if (i.name == "productList") {
          body.productName = i.item;
        } else if (i.name == "materialList") {
          body.material = i.item;
        }
      }
      this.shopDeatilListData(body).then((res) => {
        if (res.msgcode == 1) {
          this.searchResultData = res.resultdata.data;
          this.paginationTotal = res.resultdata.total;
          this.loading = false;
        }
      });
    },
    setAllsearchtHeight () {
      this.allSearchListHeight = 46 * 3;
    },
    OpenDown () {
      this.isOpenDown = !this.isOpenDown;
      if (this.isOpenDown) {
        if (this.allSearchListHeight > 46 * 3) {
          for (let i of this.clickHeight) {
            i.childHeight = 0;
          }
        }
        this.allSearchListHeight = 46 * 3;
      } else {
        if (this.allSearchListHeight > 46 * 3) {
          this.allSearchListHeight += 46 * 3;
        } else {
          this.allSearchListHeight = 46 * 3;
        }
      }
    },
    updowns (name, index) {
      if (this.clickHeight[index].childHeight > 0) {
        // this.clickHeightNum = 0;
        this.clickHeight[index].childHeight = 0;
        if (this.isOpenDown) {
          this.allSearchListHeight = 46 * 3;
        } else {
          this.allSearchListHeight = 46 * 3;
        }
      } else {
        let childWith = this.$refs[name][0].childNodes;
        let num = 0;
        for (let i of childWith) {
          num += i.offsetWidth + 22.5;
        }

        this.clickHeightNum = Math.ceil(num / 680);
        if (this.clickHeightNum <= 1) {
          this.$message.warning("当前下拉无更多选项");
        } else {
          this.clickHeight[index].childHeight = this.clickHeightNum;
          this.allSearchListHeight += 46 * this.clickHeightNum;
        }
      }
    },
    //规格输入框确定按钮  if(this.searchInput){}
    onMultipleChoice () {
      if (this.searchInput) {
        let num = 0;
        for (let i of this.searchList["specificationList"]) {
          if (String(i.name).indexOf(this.searchInput) != -1) {
            i.isActive = true;
            num++;
            this.searchConditionsList.push({
              name: "specificationList",
              item: i.name,
            });
          }
        }
        if (num == 0) {
          this.$message.error("没找到符合搜索条件的规格");
        }
      } else {
        this.$message.error("请输入正确的格式");
      }
    },
    danxuan (val, name) {
      if (this.searchConditionsList.length > 0) {
        let onlyOne = false;
        for (let i of this.searchConditionsList) {
          if (i.name == name) {
            i.item = val.name;
            onlyOne = false;
            break;
          } else {
            onlyOne = true;
          }
        }
        if (onlyOne) {
          this.searchConditionsList.push({ name, item: val.name });
        }
      } else {
        this.searchConditionsList.push({ name, item: val.name });
      }
      Object.keys(this.searchList).forEach((key) => {
        this.searchList[key].map((items) => {
          for (let i of this.searchConditionsList) {
            if (items.name == i.item) {
              items.isActive = true;
              break;
            } else {
              items.isActive = false;
            }
          }
        });
      });
    },
    onSureSearchList () {
      if (this.checkoriginPlaceList.length > 0) {
        for (let i in this.checkoriginPlaceList) {
          this.searchConditionsList.push({
            name: "originPlaceList",
            item: this.checkoriginPlaceList[i],
          });
        }
      }
      if (this.checkCityList.length > 0) {
        for (let i in this.checkCityList) {
          this.searchConditionsList.push({
            name: "cityList",
            item: this.checkCityList[i],
          });
        }
      }
    },
    duoxuan (val, name) {
      if (this.searchConditionsList.length > 0) {
        let onlyOne = false;
        for (let i of this.searchConditionsList) {
          if (i.name == name && i.item == val.name) {
            onlyOne = false;
            break;
          } else {
            onlyOne = true;
          }
        }
        if (onlyOne) {
          this.searchConditionsList.push({ name, item: val.name });
        }
      } else {
        this.searchConditionsList.push({ name, item: val.name });
      }

      Object.keys(this.searchList).forEach((key) => {
        this.searchList[key].map((items) => {
          for (let i of this.searchConditionsList) {
            if (items.name == i.item) {
              items.isActive = true;
              break;
            } else {
              items.isActive = false;
            }
          }
        });
      });
    },
    onConditions (val, name) {
      if (this.isSingleChoice) {
        this.danxuan(val, name);
      } else {
        if (
          name == "productList" ||
          name == "materialList" ||
          name == "warehouseList"
        ) {
          this.danxuan(val, name);
        }
      }
    },
    onDeleteCondtion (index, item) {
      this.searchList[item.name].map((obj) => {
        if (obj.name == item.item) {
          obj.isActive = false
        }
      });
      this.searchConditionsList.splice(index, 1);
    },
    onDeleteCondtionAll () {
      this.searchConditionsList.splice(0, this.searchConditionsList.length);
      this.setSearchList();
      this.searchInput = "";
    },
    setSearchList () {
      Object.keys(this.searchList).forEach((key) => {
        this.searchList[key].map((item) => {
          item.isActive = false;
          return;
        });
      });
    },
    moreOriginPlaceList (name, index) {
      this.isOriginPlaceList = true;
      this.isSingleChoice = false;
      this.isoriginPlaceListSingleChoice = false;
      this.$nextTick(this.updowns(name, index));
    },
    clearMoreOriginPlaceList (name, index) {
      this.allSearchListHeight =
        this.allSearchListHeight - this.clickHeight[index].childHeight * 46;
      this.clickHeight[index].childHeight = 0;
      this.isOriginPlaceList = false;
      this.isSingleChoice = true;
      this.isoriginPlaceListSingleChoice = true;
      if (this.checkoriginPlaceList.length > 0) {
        this.checkoriginPlaceList.splice(0, this.checkoriginPlaceList.length);
      }
      if (this.searchConditionsList.length > 0) {
        let arr = [];
        this.searchConditionsList.forEach((item) => {
          if (item.name != "originPlaceList") {
            arr.push(item);
          }
        });
        this.searchConditionsList = arr;
        // for(let i =0;i< this.searchConditionsList.length;i++){
        //   if(this.searchConditionsList[i].name=='originPlaceList'){
        //     this.searchConditionsList.splice(i,1)
        //   }
        // }
        for (let i of this.searchList["originPlaceList"]) {
          i.isActive = false;
        }
      }
    },
    moredCityList (name, index) {
      this.isCityList = true;
      this.isSingleChoice = false;
      this.iscityListSingleChoice = false;
      this.$nextTick(this.updowns(name, index));
    },
    clearMoredCityList (name, index) {
      this.allSearchListHeight =
        this.allSearchListHeight - this.clickHeight[index].childHeight * 46;
      this.clickHeight[index].childHeight = 0;
      this.isCityList = false;
      this.isSingleChoice = true;
      this.iscityListSingleChoice = true;
      if (this.checkCityList.length > 0) {
        this.checkCityList.splice(0, this.checkCityList.length);
      }
      if (this.searchConditionsList.length > 0) {
        let arr = [];
        this.searchConditionsList.forEach((item) => {
          if (item.name != "cityList") {
            arr.push(item);
          }
        });
        this.searchConditionsList = arr;
        // for(let i =0;i< this.searchConditionsList.length;i++){
        //   if(this.searchConditionsList[i].name=='originPlaceList'){
        //     this.searchConditionsList.splice(i,1)
        //   }
        // }
        for (let i of this.searchList["cityList"]) {
          i.isActive = false;
        }
      }
    },
  },
  watch: {
    searchConditionsList: {
      handler () {
        this.gotoScreen();
      },
      deep: true,
    },
  },
  created () {
    this.loading = true;
    this.getCompanyDeatil(this.$route.query.id).then((res) => {
      if (res.msgcode === 1) {
        Object.keys(res.resultdata).forEach((key) => {
          if (this.searchList[key]) {
            res.resultdata[key].map((item) => {
              let str = { name: item, isActive: false };
              this.searchList[key].push(str);
            });
          }
        });
        this.setAllsearchtHeight();
        let body = {
          pages: 1,
          rows: 10,
          companyId: this.$route.query.id,
        };
        this.shopDeatilListData(body).then((res) => {
          if (res.msgcode == 1) {
            this.searchResultData = res.resultdata.data;
            this.paginationTotal = res.resultdata.total;
            this.loading = false;
          }
        });
      }
    });

  },
};
</script>

<style lang="scss" scoped>
.stock-content .el-checkbox__input.is-checked + .el-checkbox__labe {
  color: #e73352 !important;
}

.stock-content .is-checked * {
  color: #e73352 !important;
}
.stock-content .is-checked .el-checkbox__inner {
  background-color: #e73352 !important;
  border-color: #e73352 !important;
}
.stock-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
.stock-content .el-collapse-item__content {
  padding-bottom: 0px !important;
}
.stock-content .stock-condition {
  width: 1200px;
  background-color: #fff;
  display: flex;
  margin: 0px auto;
}
.stock-content .stock-condition-title {
  margin-left: 16px;
  font-size: 14px;
  color: #333333ff;
  font-weight: 400;
  width: 74px;
  line-height: 52px;
}

.stock-content .stock-condition-title {
  margin-right: 11.5px;
}
.stock-condition .stock-condition-list {
  display: flex;
  flex-wrap: wrap;
  width: 1120px;
  height: auto;
  align-items: center;
}
.stock-content .stock-condition .stock-condition-item {
  display: flex;
  align-items: center;
  font-size: 12px;
  border: 1px solid #ebebeb;
  margin-right: 12px;
  color: #999999;
  max-width: 200px;
  height: 24px;
  border-radius: 2px;
  /*! line-height: 24px; */
  box-sizing: border-box;
  margin-top: 11.5px;
  margin-bottom: 11.5px;
}

.stock-content .stock-condition .stock-condition-item p {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #999999;
  /*! height: 24px; */
  /*! line-height: 24px; */
  padding: 5px 8px;
  border-radius: 2px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  cursor: pointer;
  caret-color: rgba(0, 0, 0, 0);
}

.stock-content .stock-condition .stock-condition-item img {
  width: 7.6px;
  margin: 0px 3.5px 0 0px;
  cursor: pointer;
  /*! vertical-align: middle; */
}
.stock-content .stock-condition .stock-condition-clear {
  font-size: 14px;
  color: #666666;
  font-weight: 400;
}

.stock-content .stock-detailed {
  width: 1200px;
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #ecececff;
  // height:calc(46*6px);
  border-bottom: none;
  transition: all 1s ease;
  overflow: hidden;
}

.stock-detailed-up {
  //height: 140px!important;
  transition: all 1s ease;
}

.stock-content .stock-detailed .stock-detailed-list {
  display: flex;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ededed;
}
.stock-content .detailed-list-title {
  font-size: 14px;
  text-align: center;
  color: #999;
  width: 74px;
  line-height: 46px;
}
.detailed-filler {
  width: 74px;
  background-color: #f8f8f8ff;
  padding-left: 16px;
}

.detailed-list-item-updown {
  font-size: 13px;
  cursor: pointer;
  margin: 0 11.5px;
  height: 46px;
  line-height: 46px;
}
.stock-content .detailed-list-Shell-active {
  height: 150px !important;
}
.stock-content .detailed-list-Shell {
  display: flex;
  width: 680px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  flex-wrap: wrap;
  height: 46px;
  transition: all 1s ease;
}
.stock-content .detailed-list-item {
  color: #666;
  font-size: 13px;
  font-weight: 400;
  margin: 0 11.5px;
  height: 46px;
  line-height: 46px;
  cursor: pointer;
}
.stock-content .detailed-list-item.active {
  color: #ff680a !important;
}
.stock-content .detailed-list-item:hover {
  color: #ff680a !important;
}

.stock-content .detailed-list-specs input {
  width: 170px;
  height: 26px;
  border-color: #dddddd;
  box-shadow: none;
  border-radius: 0;
  margin-right: 10px;
}
.stock-content .stock-detailed .stock-detailed-list .detailed-list-sure button {
  width: 66px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #666666ff;
  border: 1px solid #ddddddff;
  padding-left: 2px;
  background-color: #fff;
  margin-left: 15px;
}
.stock-content .stock-detailed .stock-detailed-list .detailed-list-img img {
  margin-left: 30px;
  width: 16px;
  height: 16px;
}

.stock-content .stock-detailed .stock-detailed-list .detailed-list-choice {
  width: 64px;
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
  color: #666666ff;
  border: 1px solid #ddddddff;
  text-align: center;
  margin-left: 142px;
}
.stock-content .stock-detailed .stock-detailed-list .detailed-list-choice img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  display: inline-block;
}
.stock-content .stock-detailed .stock-detailed-list .detailed-list-choice.none {
  border: none !important;
  width: 66px;
}
.stock-content .stock-detailed .stock-detailed-list .detailed-list-select img {
  margin-left: 30px;
  width: 16px;
  height: 16px;
}
.stock-content .stock-detailed-open {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #999999ff;
  height: 33px;
  align-items: center;
  background-color: #ffffff;
  cursor: pointer;
  width: 1200px;
  margin: 0 auto;
}

.stock-detailed-open-up img {
  transform: rotate(180deg);
  transition: all 1s ease;
}

.stock-content .stock-detailed-open img {
  width: 9px;
  height: 6px;
  margin-left: 4px;
}

.stock-content .stock-product {
  width: 1200px;
  border: 1px solid #ededed;
  border-bottom: none;
  margin: 20px auto;
}

.stock-content .stock-product-header {
  width: 1168px;
  height: 45px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ededed;
}
.stock-content .stock-product-header .product-header-time {
  display: flex;
  align-items: center;
  width: 92.5px;
  justify-content: center;

  font-size: 14px;
  color: #666666;
  font-weight: 400;
}
.stock-content .stock-product-header .product-header-time img {
  width: 12px;

  margin-left: 4px;
}

.stock-content .stock-product-header .product-header-price {
  display: flex;
  align-items: center;
  color: #666666;
  margin-right: 20px;
  justify-content: center;
  font-size: 14px;

  font-weight: 400;
}
.stock-content .stock-product-header .product-header-price img {
  width: 12px;

  margin-left: 4px;
}

.stock-content .stock-product-header .product-header-total {
  display: flex;
  align-items: center;
  width: 910.5px;
  justify-content: end;
  border-right: 1px solid #f1f1f1ff;
  font-size: 14px;
  color: rgba(153, 153, 153, 1);
  font-weight: 400;
}
.stock-content .stock-product-header .product-header-total p {
  margin-right: 12.5px;
}
.stock-content .stock-product-header .product-header-total p span {
  color: rgba(51, 51, 51, 1);
}
.stock-content .stock-product-header .product-header-num {
  display: flex;
  align-items: center;
  width: 92.5px;
  text-align: center;
  justify-content: center;
}
.stock-content .stock-product-header .product-header-num img {
  width: 6px;
  height: 9px;
}

.stock-content .stock-product-header .product-header-num p {
  margin: 0 8px;
}
.stock-content .stock-product-header .product-header-num p span:nth-child(1) {
  color: rgba(239, 33, 71, 1);
}

.stock-content .sock-product-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(241, 241, 241, 1);
  cursor: pointer;
  height: 98px;
}
.stock-content .sock-product-item .content_div_content {
  width: 100%;
  height: 98px;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stock-content .sock-product-item .content_div_content .div_content {
  float: left;
  width: 400px;
  padding: 0px 15px;
  font-size: 14px;
}
.stock-content
  .sock-product-item
  .content_div_content
  .div_content
  .div_content_bottom {
  width: 114px;
  height: 33px;
  border: 1px solid #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff4747;
  font-size: 16px;
  cursor: pointer;
}
.stock-content
  .sock-product-item
  .content_div_content
  .div_content
  .div_content_bottom
  .img_car {
  background: url(https://ddmallimg.ddmg.com/public/assets/img/index/layout/car.png)
    no-repeat center;
  width: 23px;
  height: 25px;
  margin-right: 5px;
}
.stock-content .sock-product-item:hover {
  background-color: rgba(249, 249, 249, 1);
}

.stock-content .sock-product-item .product-name {
  margin-left: 5%;
}
.stock-content .sock-product-item img {
  width: 80px;
  height: 80px;
  margin: 24px 16px 24px 24px;
}
.stock-content .sock-product-item img {
  width: 80px;
  height: 80px;
  margin: 24px 16px 24px 24px;
}

.stock-content .product-name {
  width: 240.5px;
}
.stock-content .product-name p:nth-child(1) {
  color: rgba(51, 51, 51, 1);
  font-size: 15px;
  font-weight: 400;
  width: 240.5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.stock-content .product-name p:nth-child(2) {
  color: rgba(153, 153, 153, 1);
  font-size: 13px;
  font-weight: 400;
  width: 240.5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  margin-top: 8px;
}
.stock-content .product-company {
  width: 254px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.stock-content .product-company .company-name {
  font-size: 15px;
  color: rgba(51, 51, 51, 1);
  font-weight: 400;
}
.stock-content .product-company .company-tell {
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-size: 13px;
  color: rgba(153, 153, 153, 1);
  position: relative;

  /*! text-align: left; */
}
.stock-content .product-company .company-tell img {
  width: 16px;
  height: 16px;
  margin: 0;
  margin-right: 4px;
}

/*需要隐藏的电话号码开始*/
.stock-content .product-company .company-tell .company-phone {
  width: 104px;
  height: 25px;
  position: absolute;
  top: -35px;
}
.stock-content .product-company .company-tell .company-phone p {
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  font-weight: 400;
  text-align: center;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
}
.stock-content .product-company .company-tell .company-phone i {
  width: 8px;
  height: 15px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-radius: 0% 0% 90% 90%;
  background-color: #fff;
  display: block;
  position: absolute;
  bottom: -5px;
  left: 2px;
}

.stock-content .sock-product-item .product-price {
  height: 60px;
  line-height: 60px;
  display: flex;
  flex-direction: column;
}
.stock-content .sock-product-item .product-price p:nth-child(1) {
  width: 100%;
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}
.stock-content .sock-product-item .product-price p:nth-child(2) {
  width: 100%;
  line-height: 18px;
  margin-top: 5px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  text-align: right;
}

.stock-content .sock-product-item .product-order {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 14px;
  color: rgba(239, 33, 71, 1);
  font-weight: 400;
  margin-right: 5%;
  cursor: pointer;
}
.stock-content .sock-product-item .product-order p {
  width: 110px;
  height: 36px;
  text-align: center;
  border: 1px solid rgba(221, 221, 221, 1);
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stock-content .sock-product-item .product-order img {
  width: 16px;
  height: 16px;
  /*! line-height: 29px; */
  padding: 5px;
  margin: 0;
  border: none;
}
.stock-content .stock-paging {
  display: flex;
  justify-content: center;
  margin-bottom: 43px;
  align-items: center;
}
.stock-content .stock-paging .stock-paging-total {
  font-size: 14px;
  color: rgba(140, 140, 140, 1);
  font-weight: 400;
  margin-right: 14.33px;
}
.stock-content .stock-paging .stock-paging-left {
  width: 23.33px;
  height: 23.33px;
  background-color: #fff;
  border-radius: 4px;
  line-height: 23.33px;
  text-align: center;
  margin-right: 14.33px;
}
.stock-content .stock-paging img {
  width: 6px;
  height: 11px;
  background-color: #fff;
  border-radius: 4px;
}
.stock-content .stock-paging .stock-paging-list ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
.stock-content .stock-paging .stock-paging-list li {
  width: 23.33px;
  height: 23.33px;
  background-color: #fff;
  border-radius: 4px;
  line-height: 23.33px;
  text-align: center;
  margin-right: 14.33px;
  font-size: 14px;
  color: rgba(140, 140, 140, 1);
}

.stock-content .stock-paging ul li.active {
  background-color: #f0f0f0;
  color: rgba(38, 38, 38, 1);
}

.stock-content .stock-paging .stock-paging-goto {
  display: flex;
  justify-content: center;
}
.stock-content .stock-paging .stock-paging-right {
  width: 23.33px;
  height: 23.33px;
  background-color: #fff;
  border-radius: 4px;
  line-height: 23.33px;
  text-align: center;
  margin-right: 14.33px;
}
.stock-content .stock-paging .stock-paging-goto {
  font-size: 14px;
  color: rgba(140, 140, 140, 1);
  font-weight: 400;
  display: flex;
  align-items: center;
}

.stock-content .stock-paging .stock-paging-goto p {
  margin-right: 14.33px;
}
.stock-content .stock-paging .stock-paging-goto input {
  width: 44px;
  height: 28px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgba(38, 38, 38, 0.17);
}
.stock-content .stock-paging .stock-paging-goto p:nth-child(3) {
  margin-left: 14.33px;
}

.stock-content .stock-detailed .stock-detailed-list.more .detailed-list-specs {
  margin-left: 125px;
  align-self: flex-start;
  margin-top: 11.5px;
}

.stock-content .stock-detailed .stock-detailed-list.more .detailed-list-sure {
  align-self: flex-start;
  margin-top: 11.5px;
}

.stock-content .stock-detailed .stock-detailed-list .detailed-list-specs input {
  width: 138px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #999999ff;
  border: 1px solid #ddddddff;
  padding-left: 2px;
  /*! margin-right: 4px; */
  box-sizing: border-box;
}

.stock-content .stock-detailed .stock-detailed-list .detailed-list-mored {
  display: flex;
  width: 208px;
  /*! line-height: 46px; */
  margin-top: 7px;
  justify-content: right;
  margin-left: 120px;
}

.stock-content
  .stock-detailed
  .stock-detailed-list
  .detailed-list-mored
  button
  img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.stock-content .stock-detailed .stock-detailed-list .detailed-list-sure button {
  width: 66px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #666666ff;
  border: 1px solid #ddddddff;
  padding-left: 2px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}

.stock-content .stock-detailed .stock-detailed-list .detailed-list-img {
  align-self: flex-start;
  margin-top: 15.5px;
}

.stock-content
  .stock-detailed
  .stock-detailed-list
  .detailed-list-img
  .list-img-mored {
  margin-left: 30px;
  width: 16px;
  height: 16px;
  caret-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background: url("../../../assets/img/downh.svg") no-repeat;
}
.list-img-mored {
  transition: all 1s ease;
  transform: rotate(0deg);
}
.list-img-mored:hover {
  background: url("../../../assets/img/downred.svg") no-repeat !important;
}
.list-img-mored-up {
  background: url("../../../assets/img/uph.svg") no-repeat;
  transform: rotate(180deg);
  transition: all 1s ease;
}
.list-img-mored-up:hover {
  background: url("../../../assets/img/downred.svg") no-repeat !important;
}

.stock-content .div_404 {
  width: 1200px;
  height: 500px;
  margin: 0 auto;
  text-align: center;
}
.stock-content .div_404_left {
  width: 350px;
  height: 300px;
  background: url(https://ddmallimg.ddmg.com/public/assets/img/index/mall/404.png)
    no-repeat center;
  background-size: 100% 100%;
  float: left;
  margin-left: 220px;
  margin-top: 100px;
}
.stock-content .div_404_right {
  float: left;
  height: 300px;
  margin-top: 100px;
  margin-left: 20px;
  text-align: left;
}
.stock-content .div_404_right h3 {
  font-size: 24px;
  color: #ff6200;
  margin-top: 68px;
  text-align: left;
  font-weight: 400;
}
.stock-content .div_404_right h4 {
  font-size: 16px;
  color: #999999;
  font-weight: 400;
}
.stock-content .div_404_right .btnqiug {
  width: 210px;
  height: 42px;
  text-align: center;
  background-color: #ff6200;
  color: #ffffff;
  border: none;
  font-size: 16px;
  margin-top: 10px;
  outline: none;
}
// .stock-content .detailed-list-specs {
//   margin-top: 2px;
// }

.stock-content .priceSort,
.timeSort {
  transform: rotate(180deg);
}
</style>
